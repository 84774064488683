// extracted by mini-css-extract-plugin
export var blockA = "expertiseDomain-module--blockA--f0231";
export var blockAImg = "expertiseDomain-module--blockAImg--d29ea";
export var blockB = "expertiseDomain-module--blockB--e70a8";
export var blockC = "expertiseDomain-module--blockC--cb8fb";
export var blockD = "expertiseDomain-module--blockD--bf41c";
export var blockE = "expertiseDomain-module--blockE--f9291";
export var expertiseJob = "expertiseDomain-module--expertiseJob--9c538";
export var expertiseJobImage = "expertiseDomain-module--expertiseJobImage--ee30b";
export var expertiseJobLeftContainer = "expertiseDomain-module--expertiseJobLeftContainer--bc6d2";
export var page = "expertiseDomain-module--page--47851";