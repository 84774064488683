import * as React from 'react';

import {
    graphql, HeadProps 
} from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import { Head as DefaultHead } from '../../components/head/head.component';
import { NosExpertisesBlock } from '../../components/nos-expertises-page/our-values-block/nos-expertises-block.component';
import { Banner } from '../../components/shared/banner/banner.component';
import { BlogPostsCarousel } from '../../components/shared/blog-posts-carousel/blog-posts-carousel.component';
import { CustomHeader } from '../../components/shared/custom-header/custom-header.component';
import { ExpertiseCertificationBlock } from '../../components/shared/expertise-certification-block/expertise-certification-block.component';
import { TitleBlock } from '../../components/shared/title-block/title-block.component';
import { seo } from '../../config/seo-config';
import { GatsbyStrapiPictureField } from '../../models/cms-common';
import {
    buildGatsbyStrapiImage,
    isUndOrEmpty
} from '../../utils';
import * as styles from './expertiseDomain.module.css';

export const query = graphql`
    query ExpertiseDomainQuery($slug: String!) {
        strapiExpertiseDomain(slug: { eq: $slug }) {
            slug
            title
            subtitle
            picture {
                localFile {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }

            expertiseJobs {
                title
                description
                picture {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }

            expertiseActions {
                title
                description
                picture {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
            certifTitle
            certifSubtitle
        }

        allStrapiCertifications(sort: {fields: id, order: ASC}) {
            nodes {
                slug
                CertificationBadge {
                    title
                    subtitle
                    description
                    ctaText
                    ctaLink
                    picture {
                        localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }

        allStrapiArticle(
            filter: { category: { slug: { eq: $slug } } }
            limit: 10
            sort: { order: DESC, fields: published_at }
        ) {
            nodes {
                id
                slug
                title
                author
                subtitle
                bannerPicture {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                category {
                    id
                    slug
                    color
                }
                tags {
                    id
                    slug
                }
            }
        }

        strapiNosExpertises {
            expertiseDomains {
                title
                description
                picture {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                ctaLink
                ctaText
            }
        }
    }
`;

interface ExpertiseDomainProps {
    data: {
        strapiExpertiseDomain: {
            slug: string;
            title: string;
            subtitle: string;
            picture: GatsbyStrapiPictureField;
            expertiseJobs: {
                title: string;
                description: string;
                picture: GatsbyStrapiPictureField;
            }[];
            expertiseActions: {
                title: string;
                description: string;
                picture: GatsbyStrapiPictureField;
            }[];
            certifTitle: string;
            certifSubtitle: string;
        };

        allStrapiArticle: {
            nodes: {
                id: string;
                slug: string;
                title: string;
                subtitle: string;
                bannerPicture: GatsbyStrapiPictureField;
                category: {
                    id: string;
                    slug: string;
                };
                tags: {
                    id: string;
                    slug: string;
                }[];
            }[];
        };

        allStrapiCertifications: {
            nodes: {
                slug: string;
                CertificationBadge: {
                    title: string;
                    subtitle: string;
                    description: string;
                    ctaText: string;
                    ctaLink: string;
                    picture: GatsbyStrapiPictureField;
                };
            }[];
        };

        strapiNosExpertises: {
            expertiseDomains: ({
                title: string;
                description: string;
                picture: GatsbyStrapiPictureField;
                pictureHover: GatsbyStrapiPictureField;
                ctaText: string;
                ctaLink: string;
            })[];
        }
    };
}

const ExpertiseDomainPage: React.FC<ExpertiseDomainProps> = ({ data }: ExpertiseDomainProps) => {
    const title = data.strapiExpertiseDomain.title;
    const subtitle = data.strapiExpertiseDomain.subtitle;
    const picture = buildGatsbyStrapiImage(data.strapiExpertiseDomain.picture);

    const certifTitle = data.strapiExpertiseDomain.certifTitle;
    const certifSubtitle = data.strapiExpertiseDomain.certifSubtitle;

    const recentBlogPosts = data.allStrapiArticle.nodes;

    const expertiseJobs = data.strapiExpertiseDomain.expertiseJobs;
    const expertiseActions = data.strapiExpertiseDomain.expertiseActions;

    const certifications = data.allStrapiCertifications.nodes.filter(n => {
        const split = n.CertificationBadge.description.split(',');
        return split.find(s => data.strapiExpertiseDomain.slug === s) !== undefined;
    });

    const otherExpertiseDomains = data.strapiNosExpertises.expertiseDomains.filter(ed => ed.title !== title);

    return (
        <>
            <CustomHeader transparent={false} dark={false} backgroundColor='var(--alt-blue-01)' />
            <div className={`d-flex flex-column align-items-center justify-content-start ${styles.page}`}>
                <div className={styles.blockA}>
                    <Banner title={title} subtitle={subtitle} backgroundColor="var(--alt-blue-01)" />
                    <div className={styles.blockAImg}>
                        {picture && (
                            <GatsbyImage image={picture} alt={title} className='w-100' />
                        )}
                    </div>
                </div>

                {expertiseJobs && expertiseJobs.length > 0 && (
                    <div
                        className={`${styles.blockB}
                                col-12 col-xl-8 d-flex align-items-start justify-content-center`}
                    >
                        <div className="container row align-items-center">
                            {expertiseJobs.map((expertiseJob, index: number) => {
                                const image = buildGatsbyStrapiImage(expertiseJob.picture);
                                return (
                                    <div key={`${expertiseJob.title}_${index}`} className="col-12 col-xl-6">
                                        <div className={`${styles.expertiseJob} d-flex align-items-center m-3`}>
                                            <div className="row p-0 m-0">
                                                <div
                                                    className={`
                                                ${styles.expertiseJobLeftContainer}
                                                col-12 col-xl-4 d-flex flex-column justify-content-center
                                            `}
                                                >
                                                    <div className="text-center mt-4 mt-xl-0">
                                                        {image ? (
                                                            <GatsbyImage
                                                                image={image}
                                                                alt={expertiseJob.title}
                                                                className={`${styles.expertiseJobImage}`}
                                                            />
                                                        ) : null}
                                                    </div>
                                                    <div className="text-center my-1">
                                                        <h4 className="m-0 p-0">{expertiseJob.title}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-8 d-flex align-items-center">
                                                    <div className="text-center text-xl-left m-2">
                                                        {!isUndOrEmpty(expertiseJob.description) && (
                                                            <ReactMarkdown>{expertiseJob.description}</ReactMarkdown>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}

                {expertiseActions && expertiseActions.length > 0 && (
                    <div
                        className={`${styles.blockB}
                                col-12 col-xl-8 d-flex align-items-start justify-content-center`}
                    >
                        <div className="container row align-items-center">
                            {expertiseActions.map((expertiseAction, index: number) => {
                                const image = buildGatsbyStrapiImage(expertiseAction.picture);
                                return (
                                    <div
                                        key={`${expertiseAction.title}_${index}`}
                                        className="col-12 col-xl-6 d-flex align-self-stretch"
                                    >
                                        <div className={`${styles.expertiseJob} d-flex align-items-center py-3 m-3`}>
                                            <div className="row p-0 m-0">
                                                <div className="col-12 d-flex flex-column justify-content-center">
                                                    <div className="text-center mt-4">
                                                        {image ? (
                                                            <GatsbyImage
                                                                image={image}
                                                                alt={expertiseAction.title}
                                                                className={`${styles.expertiseJobImage}`}
                                                            />
                                                        ) : null}
                                                    </div>
                                                    <div className="text-center my-1">
                                                        <h4>{expertiseAction.title}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex align-items-center">
                                                    <div className="text-center m-2">
                                                        {!isUndOrEmpty(expertiseAction.description) && (
                                                            <ReactMarkdown>{expertiseAction.description}</ReactMarkdown>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}

                {certifications && certifications.length > 0 && (
                    <div className={styles.blockC}>
                        <ExpertiseCertificationBlock
                            certifications={certifications}
                            title={certifTitle}
                            subtitle={certifSubtitle}
                        />
                    </div>
                )}

                {recentBlogPosts && recentBlogPosts.length > 0 && (
                    <div className={`w-100 ${styles.blockD}`}>
                        <BlogPostsCarousel posts={recentBlogPosts} />
                    </div>
                )}

                {otherExpertiseDomains && otherExpertiseDomains.length > 0 && (
                    <div className={`pt-5 ${styles.blockE}`}>
                        <div className='col-11 col-sm-10'>
                            <TitleBlock className='text-uppercase'>NOS AUTRES EXPERTISES</TitleBlock>
                        </div>
                        <div className='col-11 col-sm-10'>
                            <NosExpertisesBlock expertiseDomains={otherExpertiseDomains} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ExpertiseDomainPage;

export const Head = ({ data }: HeadProps<{ strapiExpertiseDomain: { slug: string } }>) => {
    const { strapiExpertiseDomain: { slug } } = data;
    const seoData = seo.nosExpertises.children ? seo.nosExpertises.children[slug] : {};
    return (
        <DefaultHead {...seoData} />
    );
};