import * as React from 'react';

import { BannerProps as CmsBannerProps } from '../../../models/cms-common';
import { isUndOrEmpty } from '../../../utils/string-utils';
import * as styles from './banner.module.css';

interface BannerProps extends CmsBannerProps {
    backgroundColor?: string;
}

export const Banner: React.FC<BannerProps> = ({
    title,
    subtitle,
    backgroundColor = 'var(--alt-blue-01)'
}: BannerProps) => {
    return (
        <div className={styles.bannerRoot} style={{ backgroundColor }}>
            {!isUndOrEmpty(title) && (
                <div className='mt-md-5'>
                    <h2 className={styles.title}>{title}</h2>
                </div>
            )}
            {!isUndOrEmpty(subtitle) && (
                <div className={`mt-4 mx-2 ${styles.subtitle}`}>
                    {subtitle}
                </div>
            )}
        </div>
    );
};